import { Record } from './record/record';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from './store';

export const url = 'https://dmsharvestapp.com';
//export const url = 'https://159.65.69.72:9898';

export const headings: string[] = [
  'Orchard',
  'KPIN',
  'Maturity Area',
  'Variety',
  'Contractor',
  'Bins Estimate',
  'Bins Actual',
  'Status',
  'Comments',
  'Picking Rate (Bins/Hour)'
];

export const status: string[] = [
  'MA Completed',
  'Finished for day, MA Incomplete',
  'In Progress',
  'Not yet started'
];

export const transporters: string[] = [
  'Dalziel',
  'Langfords',
  'OMS',
  'Self',
  'Stewart'
];

// not used, to be removed.
export const contractors: string[] = [
  'Bay Orchards',
  'Dala',
  'Dalziel',
  'Dosanjh',
  'Erlon',
  'Garcia',
  'Lal',
  'Lehember',
  'Montrose',
  'OMS',
  'Pap Hort',
  'Parram',
  'Self',
  'Top Grow',
  'Trinity Lands'
];

export const specifications = [
  {
    heading: 'Picking Quality',
    specs: [
      `No raining observed (same picker raining twice in 1 hour)
            If see more than 3 people raining more than once, see audit manager`,
      `No poor delivery-no drum roll or banging bag on side of bin
            (same picker twice in 1 hour)`,
      'No unnecessary dropped fruit',
      `No missing fruit (no more than 1 per bay average across 10 bays)`,
      'No stalks in bin',
      'Bins are adequately filled and levelled'
    ],
    values: [
      30,
      30,
      10,
      10,
      10,
      10
    ],
    weight: 1
  }
];


export function statusClass(stat: string, kind: string) {
  switch (stat) {
    // case status[0]:
    //     return kind + '-secondary';
    case status[2]:
      return kind + '-success';
    case status[1]:
      return kind + '-warning';
    case status[0]:
      return kind + '-danger';
    default:
      return '';
  }
}

export function statusSort(a: Record, b: Record) {
  return status.indexOf(a.status) - status.indexOf(b.status);
}

export const packhouse: string[] = [
  'Te Puke',
  'Te Puna'
];

export const varieties: string[] = [
  'HW',
  'GA',
  'RS',
  'OB',
  // 'ZFP',
  // 'G11'
];

export const roles: string[] = [
  'admin',
  'harvest coordinator',
  'harvest manager',
  'audit manager',
  'auditor',
  'self',
  'contractor',
  'transport'
];

export const managers: string[] = roles.slice(0, 4);

export const totals: string[] = ['binsPicked', 'binsEstimate'];




export function pickingRate(record: Record): number {
  const num = parseInt(record.binsPicked, 10);
  return num / pickingDuration(record).asHours();
}

function pickingDuration(record): moment.Duration {
  const start = moment(record.startTime, 'HH:mm:ss');
  const update = moment(record.updateTime, 'HH:mm:ss');
  return moment.duration(update.diff(start));
}

export function pickingTime(record: Record): string {
  return pickingDuration(record).humanize();
}

export function report(body): void {
  console.log('Sending:')
  console.log(body)
}

export const hashPassword = async (password: string): Promise<string> => {
  const crypto = window.crypto.subtle
  const hash = await crypto.digest('SHA-512', new TextEncoder().encode(password + 'ILoveKiwiFruit'));
  return String.fromCharCode.apply(null, new Uint8Array(hash))
}


/*
  Error codes for the server

  500 Server error, could be database
  399 New user, needs to make a password
  398 User needs to enter a password to get a token
  397 No user exists
  396 Request needs a higher authed user

*/


export const reRouteWithError = (router: Router, res: HttpErrorResponse): void => {
  switch (res.status) {
    case 500:
      //do nothing
      break;
    case 399:
      router.navigate(['/login/newPassword']);
      break;
    case 398:
      router.navigate(['/login/password']);
      break;
    case 397:
      router.navigate(['/login/user']);
      User.store(new User());
      break;
    case 403:
      router.navigate(['login/badPassword']);
      break;
    case 200:
      break;
    default :
      console.error('Unhandled server error : ' + res.status);
      router.navigate(['/'])
      break;
  }
}
